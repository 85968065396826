import React from 'react';
import AboutImg from '../../imgs/about.png';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image';
import YoutubeImg from '../../imgs/youtube.png';
import QuestionBankImg from '../../imgs/qblogo.png';
import BusinessLogoImg from '../../imgs/businesslogo.png';
import ChatImg from '../../imgs/chatlogo.png';
import LinkedInImg from '../../imgs/linkinlogo.png';
import '../../styles/_about.scss'

const About = () => {
    return (
        <Container className='about'>
            <Row className='top'>
                <Col  lg={{ span: 6, order: 1 }} s={{ span: 12, order: "last" }} xs={{ span: 12, order: "last" }} className='my-auto title'>
                    <h1>About Me</h1>
                    <p>
                        Full-Time AWS Data Guru & Part-time YouTuber with a Rugby obsession.
                        <br></br>Partial to a pint of Guiness and a chat.
                    </p>
                </Col>
                <Col  lg={{span:6, order:2}} s={{span: 12 , order: "last"}} xs={{span: 12 , order: "first"}}>
                    <img id='home-img' src={AboutImg}></img>
                </Col>
            </Row>
            <Row className='middle'>
                <Row className='mx-auto'>
                    <Col className='text-center workon'>
                        <h2>
                            What Am I Currently working on?
                        </h2>
                        <br></br>
                    </Col>
                </Row>
                <Row>
                    <Col className='my-auto content'>
                        <Image id='middleImg' src={YoutubeImg} thumbnail />
                        <h4>YouTube</h4>
                        <p>
                            My youtube channel consists of videos on all things data engineering and AWS from the very basics to the advanced stuff. I upload content regular and making everything freely avaialable. You can check it out <a href="https://www.youtube.com/johnnychivers">here</a> on youtube, or <a href="https://johnnychivers.co.uk/lessons">here</a> on this website.
                        </p>
                    </Col>
                </Row>
                <Row>
                <Col className='my-auto content'>
                        <Image id='middleImg' src={QuestionBankImg} thumbnail />
                        <h4>The QuestionBank</h4>
                        <p>
                            The free quiz app of AWS questions created by the community for the community. I create the web app using AWS Amplify with the intention of making it the biggest community driven question bank on the web. Sign-Up for free <a href="https://www.thequestionbank.io/">here</a> .
                        </p>
                    </Col>

            </Row>
            <Row className='bottom'>
                <Row className='mx-auto'>
                    <Col className='text-center'>
                        <h2>
                            Other Stuff
                        </h2>
                        <br></br>
                    </Col>
                </Row>
                <Row>
                    <Col className='my-auto content'>
                        <Image id='middleImg' src={BusinessLogoImg} thumbnail />
                        <h4>Got a Business Idea?</h4>
                        <p>
                            Got a business idea you want to discuss? I am always open to offers and love meeting like minded people. Whether its just a chat, or a partner you are looking hit me up in the contact section <a href="https://www.johnnychivers.co.uk/contact">here</a>.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className='my-auto content'>
                        <Image id='middleImg' src={ChatImg} thumbnail />
                        <h4>General Chat?</h4>
                        <p>
                            In the Belfast, NI area and what a chat? or if not, a virtual get together? I am always open to a coffee or a Guiness. Hit me up via <a href = "mailto: johnny@johnnychivers.co.uk">email</a>.
                        </p>
                    </Col>
                </Row>
                <Row>
                <Col className='my-auto content'>
                        <Image id='middleImg' src={LinkedInImg} thumbnail />
                        <h4>LinkedIn</h4>
                        <p>
                            What to connect on a professional level? Then add me on <a href="https://www.linkedin.com/in/johnny-chivers">LinkedIn</a>.
                        </p>
                    </Col>
                </Row>
                    
                </Row>
                <Row>
                </Row>
            </Row>
        </Container>

    )
}

export default About;