import React, { useState, useEffect } from 'react';
import NavBar from './components/nav/NavBar';
import Home from './components/layouts/Home';
import Footer from './components/nav/Footer';
import About from './components/layouts/About';
import Contact from './components/layouts/Contact';
import Lessondetail from './components/layouts/LessonsDetail';
import LessonsSearch from './components/layouts/LessonsSearch';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import './App.scss';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import TagManager from 'react-gtm-module'

Amplify.configure(awsconfig);

const tagManagerArgs = {
  gtmId: 'GTM-M9CXVVSC'
}; 
TagManager.initialize(tagManagerArgs); 



function App() {


  return (
    <Router>
      <NavBar />
      <div className="App">
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/about' exact component={About} />
          <Route path='/contact' exact component={Contact} />
          <Route path='/courses' exact component={LessonsSearch} />
          <Route path='/course' exact component={Lessondetail} />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
