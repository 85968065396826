import Badge from 'react-bootstrap/Badge';

const TagRender = (tagArray) => {

    let listOfTags = []

    console.log(tagArray)

    // PLATFORM
    if (tagArray.platform) {
        listOfTags.push(
            <><Badge pill bg="warning">
                {tagArray.platform}
            </Badge>{' '}</>
        )
    }
    // Host
    if (tagArray.host) {
        listOfTags.push(
            <><Badge pill bg="danger">
                {tagArray.host}
            </Badge>{' '}</>
        )
    }
    // level
    if (tagArray.level) {
        listOfTags.push(
            <><Badge pill bg="primary">
                {tagArray.level}
            </Badge>{' '}</>
        )
    }
    // cost
    if (tagArray.cost) {
        listOfTags.push(
            <><Badge pill bg="success">
                {tagArray.cost}
            </Badge>{' '}</>
        )
    }




    return listOfTags
};

export default TagRender;

