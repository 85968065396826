import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import LessonsImg from '../../imgs/lessonpage.png';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom';
import { API } from 'aws-amplify';
import { AmplifyS3Image } from "@aws-amplify/ui-react";
import '../../styles/_lessonssearch.scss';
import TagRender from '../functions/TagRender'




const LessonsSearch = () => {

    const [searchterm, setSearchterm] = useState("");
    const [lessonsData, setLessonsData] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getLessons();
    }, []);

    const searchTextInput = (e) => {
        setSearchterm(e.target.value)
        console.log(e.target.value)
    }

    const getLessons = () => {
        const apiName = 'lessonsearch';
        const path = '/lessonsearch';
        const myInit = { // OPTIONAL
            headers: {
                "Content-Type": "application/json"
            }, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {  // OPTIONAL need a comment to force a clean build
            },
        };
        API.get(apiName, path, myInit).then(response => {
            console.log(response.data)
            setLessonsData(response.data.Items)
            setLoading(false)
        }).catch(error => { console.log(error) });
    }

    return (
        <Container className='lessons'>
            <Row className='top'>
                <Col lg={{ span: 1, order: 1 }}></Col>
                <Col lg={{ span: 5, order: 1 }} s={{ span: 12, order: "last" }} xs={{ span: 12, order: "last" }} className='my-auto title'>
                    <h1>AWS Courses</h1>
                    <p>
                        Below is all the AWS Lessons and Courses I have created.
                        <br /> Enjoy the learning!
                    </p>
                </Col>
                <Col lg={{ span: 5, order: 2 }} s={{ span: 12, order: "last" }} xs={{ span: 12, order: "first" }}>
                    <img id='lessonsearch-img' src={LessonsImg}></img>
                </Col>
                <Col lg={{ span: 1, order: 1 }}></Col>
            </Row>
            <Row className='bottom'>
                {loading &&
                    <Row>
                        <Col lg={12} className="search text-center">
                            <div className="spinner text-center">
                                <Spinner animation="border" />
                            </div>
                        </Col>
                    </Row>
                }
                {!loading &&
                    <Row>
                        <Col className="search text-center" lg={12}>
                            <h2>Search</h2>
                            <br />
                            <InputGroup className="mx-auto searchBox">
                                <FormControl
                                    size="lg"
                                    className="text-center rounded"
                                    placeholder="Type to start searching..."
                                    aria-label="Lesson"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => searchTextInput(e)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                }
                {!loading &&

                    lessonsData.filter((val) => {
                        if (searchterm == "") {
                            return val;
                        } else if (val.keywords.toLowerCase().includes(searchterm.toLowerCase())) {
                            return val
                        }
                    }).map((val, key) => {
                        return  <Link to={{
                            pathname: "/course", state: { uuid: val.uuid, title: val.title, subtitle: val.subtitle, description: val.description, lenght: val.length, tags: val.tags, imglocation: val.imglocation },
                        }}  style={{ textDecoration: 'none',   color: 'black' , display: 'flex', paddingTop: '3rem'}}>
                            <Row id="lessonsearchrow">
                                <Col id='lessonsearch-col-img' lg={1}></Col>
                                <Col id='lessonsearch-col-img' lg={5}>
                                    <AmplifyS3Image id="title-img" level="public"
                                        imgKey={val.imglocation} />
                                </Col>
                                <Col id='lessonsearch-col-text' lg={5}>
                                    <h2>{val.title}</h2>
                                    <h4>{val.subtitle}</h4>
                                    <h5>{val.description}</h5>
                                    <h6>Length {val.length}</h6>
                                    {TagRender(val.tags)}
                                </Col>
                                <Col id='lessonsearch-col-img' lg={1}></Col>
                            </Row>
                            
                        </Link>
                    })
                }
            </Row>
        </Container>
    );
};
export default LessonsSearch;