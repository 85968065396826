import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ContactImg from '../../imgs/contactpage.png';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { API } from 'aws-amplify';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../../styles/_contact.scss'
import Spinner from 'react-bootstrap/Spinner';
const Contact = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [body, setBody] = useState("");
    const [emailSending, setEmailSending] = useState(false)
    const [emailSent, setEmailSent] = useState(false);
    const [faliure, setFailure] = useState(false);



    const sendBtn = () => {
        setEmailSending(true);
        const apiName = 'email';
        const path = '/email';
        const myInit = { // OPTIONAL
            headers: {
            }, // OPTIONAL
            body: {
                name: name,
                email: email,
                body: body
            },
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {  // OPTIONAL need a comment to force a clean build
            },
        };
        API.put(apiName, path, myInit).then(response => {
            console.log(response)
            setFailure(false);
            setEmailSent(true);
            setEmailSending(false);
        }).catch(error => { console.log(error) });
        setEmailSent(false);
        setFailure(true)
        setEmailSending(false);
    }

    return (
        <Container className='contact'>
            <Row className='top'>
                <Col lg={{ span: 6, order: 1 }} s={{ span: 12, order: "last" }} xs={{ span: 12, order: "last" }} className='my-auto'>
                    {!emailSending && !emailSent && !faliure &&
                        <Form className="contact2-form validate-form boxes">
                            <h1>
                                Contact Us
                            </h1>
                            <Form.Control className="name" placeholder="NAME" onChange={(e) => setName(e.target.value)}></Form.Control>
                            <Form.Control className="email" type="text" placeholder="EMAIL" name="email" onChange={(e) => setEmail(e.target.value)}></Form.Control>
                            <Form.Control className="message" as="textarea" name="message" rows={8} placeholder="MESSAGE" onChange={(e) => setBody(e.target.value)}></Form.Control>
                            <Button onClick={sendBtn} size="lg" className="contact2-form-btn">
                                {
                                    emailSending &&
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                }

                                Send Your Message

                            </Button>
                        </Form>
                    }
                    {emailSending &&
                        <div>
                            <h2>
                                Email Sent
                            </h2>
                            <div className="spinner text-center">
                                <Spinner animation="border" />
                            </div>
                        </div>
                    }
                    {!emailSending && emailSent && !faliure &&
                        <div>
                            <h2>
                                Email Sent
                            </h2>
                            <Link className="nav-link" to="/">
                                <Button>
                                    Home
                                </Button>
                            </Link>
                        </div>
                    }
                </Col>
                <Col lg={{ span: 6, order: 2 }} s={{ span: 12, order: "last" }} xs={{ span: 12, order: "first" }}>
                    <img id='home-img' src={ContactImg}></img>
                </Col>
            </Row>
        </Container>
    )

}

export default Contact;