import React from 'react';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import HomeImg from '../../imgs/homepage.png';
import YoutubeImg from '../../imgs/youtube.png';
import LinkedInImg from '../../imgs/linkinlogo.png';
import GitLogo from '../../imgs/github.png';
import '../../styles/_home.scss';
import { Link } from 'react-router-dom';
const Home = () => {
    return (
        <Container className='home'>
            <Row className='top'>
                <Col lg={{ span: 6, order: 1 }} s={{ span: 12, order: "last" }} xs={{ span: 12, order: "last" }} className='my-auto welcome'>
                    <h1>Johnny Chivers</h1>
                    <h2>Welcome</h2>
                    <p>
                        Full-Time AWS Data Guru & Part-time YouTuber
                        <br></br>
                        Making content for learning AWS
                    </p>
                </Col>
                <Col lg={{ span: 6, order: 2 }} s={{ span: 12, order: "last" }} xs={{ span: 12, order: "first" }}>
                    <img id='home-img' src={HomeImg}></img>
                </Col>
            </Row>
            <Row className='middle'>
                <Row className='mx-auto title'>
                    <Col lg={12} className='text-center'>
                        <h2>
                            Why Don't We Connect?
                        </h2>
                        <br></br>
                        <br></br>
                    </Col>
                </Row>
                <Row className="content">
                    <Col lg={4} className='my-auto first' onClick={() => window.open("https://www.youtube.com/johnnychivers", "_blank")}>
                        <Image id='middleImg' src={YoutubeImg} thumbnail />
                        <h4>YouTube</h4>
                        <p>
                            Checkout all the free videos on Youtube, and why not subscribe whilst you are there?
                        </p>
                    </Col>
                    <Col lg={4} className='my-auto mid' onClick={() => window.open("https://www.linkedin.com/in/johnny-chivers", "_blank")}>
                        <Image id='middleImg' src={LinkedInImg} thumbnail />
                        <h4>LinkedIn</h4>
                        <p>
                            Why Don’t we conect? Add me on LinkedIn.
                        </p>
                    </Col>
                    <Col lg={4} className='my-auto last' onClick={() => window.open("https://github.com/johnny-chivers", "_blank")}>
                        <Image id='middleImg' src={GitLogo} thumbnail />
                        <h4>GitHub</h4>
                        <p>
                            Connect with me on GitHub where I put all the code for my demos
                        </p>
                    </Col>
                </Row>
            </Row>
            <Row className='bottom text-center'>
                <Col lg={12} className='text-center'>
                    <h2>Support The Channel</h2>
                    <br></br>
                    <p>
                       Any donations welcome to help support the channel. This helps me continue to make the content for free.
                       <br></br>
                    </p>
                    <Button id="signup" className="mx-auto" onClick={() => window.open("https://www.buymeacoffee.com/johnnychivers/", "_blank")} size="lg">Buy Me A Coffee</Button>
                </Col>
            </Row>
        </Container>

    )

}
export default Home;