import React from 'react';
import { Navbar, Nav} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import logo from '../../imgs/logo.png'
import '../../styles/_nav.scss'
const NavBar = () => {
    return (
        <Navbar expand="lg">
             <Nav.Link  className="navbar-brand" href="#"><img src={logo} width="50" height="50" boarder="0"></img></Nav.Link >
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <Nav.Link >
                      <Link className="nav-link" to="/"> Home</Link>
                    </Nav.Link>
                    <Nav.Link >
                    <Link className="nav-link" to="/courses">Courses</Link>
                    </Nav.Link>
                    <Nav.Link >
                    <Link className="nav-link" to="/about">About</Link>
                    </Nav.Link>
                    <Nav.Link >
                    <Link className="nav-link" to="/contact">Contact</Link>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )



}
export default NavBar;