import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import '../../styles/_footer.scss'

const Footer = () => {

    return (
        <Row className='footer text-center'>
            <Col lg={4}>
                <b>Contact</b>
                <p>
                    info@johnnychivers.co.uk <br />
                    <Link className="text-center" to="/contact">
                        Contact Form
                    </Link> <br />
                    <a href="https://www.linkedin.com/in/johnny-chivers">
                        LinkedIn
                    </a>
                </p>
            </Col>
            <Col lg={4}>
                <b>Website</b>
                <p>
                    <Link className="text-center" to="/">
                        home
                    </Link><br />
                    <Link className="text-center" to="/courses">
                        courses
                    </Link><br />
                    <Link className="text-center" to="/about">
                        about
                    </Link><br />
                </p>
            </Col>
            <Col lg={4}>
                <b>Social</b>
                <p>
                    <a href="https://www.youtube.com/johnnychivers">
                        YouTube
                    </a><br />
                    <a href="https://www.linkedin.com/in/johnny-chivers">
                        LinkedIn
                    </a><br />
                    <a href="https://github.com/johnny-chivers">
                        GitHub
                    </a>
                </p>
            </Col>
        </Row>
    )
};

export default Footer;