import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom"
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import YoutubeEmbed from '../youtube/YouTubeEmbedId';
import Spinner from 'react-bootstrap/Spinner';
import '../../styles/_lessonsdetail.scss'
import { API } from 'aws-amplify';
import TagRender from '../functions/TagRender'
import { AmplifyS3Image } from "@aws-amplify/ui-react";

const Lessonsdetail = () => {

    const location = useLocation()
    const uuid = location.state?.uuid
    const title = location.state?.title;
    const subtitle = location.state?.subtitle;
    const description = location.state?.description;
    const length = location.state?.length;
    const tags = location.state?.tags;
    const imglocation = location.state?.imglocation;

    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getVideos();
    }, []);

    const getVideos = () => {
        const apiName = 'lessondetail';
        const path = '/lessondetail';
        const myInit = { // OPTIONAL
            headers: {
                "Content-Type": "application/json"
            }, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {
                uuid: uuid
            },
        };
        API.get(apiName, path, myInit).then(response => {
            console.log(response.data.Items[0])
            setVideos(response.data.Items[0])
            setLoading(false)
        }).catch(error => { console.log(error) });
    }

    const requirment = (requirements) => {
        var halfLength = Math.ceil(requirements.length / 2);
        var leftSide = requirements.slice(0, halfLength);
        var rightSide = requirements.slice(halfLength);
        return (<>
            <Row  id='requirment'>
                <Col lg={1}></Col>
                <Col lg={5}> <h3>Requirements For Course</h3></Col>
                <Col lg={1}></Col>
            </Row>
            <Row id="requirements-list">
                <Col lg={1}></Col>
                <Col lg={5}>
                    {
                        leftSide.map(requirement => (
                            <>     &#10004; {requirement}<br /></>
                        ))
                    }
                </Col>
                <Col lg={5}>
                    {
                        rightSide.map(requirement => (
                            <>     &#10004; {requirement}<br /> </>
                        ))
                    }
                </Col>
                <Col lg={1}></Col>
            </Row>
        </>
        );
    }



    const whatyoulllearn = (learn) => {
        var halfLength = Math.ceil(learn.length / 2);
        var leftSide = learn.slice(0, halfLength);
        var rightSide = learn.slice(halfLength);
        return (<>
            <Row>
                <Col lg={1}></Col>
                <Col lg={5}> <h3>What You Will Learn</h3></Col>
                <Col lg={1}></Col>
            </Row>
            <Row id="learn-list">
                <Col lg={1}></Col>
                <Col lg={5}>

                    {
                        leftSide.map(learn => (
                            <>     &#10004; {learn}<br /> </>
                        ))
                    }
                </Col>
                <Col lg={5}>

                    {
                        rightSide.map(learn => (
                            <>     &#10004; {learn} <br /></>
                        ))

                    }
                </Col>
                <Col lg={1}></Col>
            </Row>
        </>
        );
    }


    return (<Container className='course-videos'>
        <Row className='top'>
            <Col lg={1}></Col>
            <Col lg={{ span: 5, order: 1 }} s={{ span: 12, order: "last" }} xs={{ span: 12, order: "last" }} className='my-auto title'>
                <h2>{title}</h2>
                <h4>{subtitle}</h4>
                <h5>{description}</h5>
                <h6>Length {length}</h6>
                {TagRender(tags)}
            </Col>
            <Col lg={{ span: 5, order: 2 }} s={{ span: 12, order: "last" }} xs={{ span: 12, order: "first" }}>
                <AmplifyS3Image id="title-img" level="public"
                    imgKey={imglocation} />
            </Col>
            <Col lg={1}></Col>
        </Row>
        <Row className='bottom'>

            {loading &&
                <Row>
                    <Col lg={12} className="search text-center">
                        <div className="spinner text-center">
                            <Spinner animation="border" />
                        </div>
                    </Col>
                </Row>
            }
            {!loading &&

                whatyoulllearn(videos.learn)
            }
            {!loading &&
                requirment(videos.requirements)

            }
            {!loading &&

                videos.lessons.map((val, key) => {
                    return (<>
                        <Row  id='lessons'>
                            <Col lg={1}></Col>
                            <Col lg={6}>
                                <h3>{val.title}</h3>
                            </Col>
                            <Col lg={4}>
                            </Col>
                            <Col lg={1}></Col>
                        </Row>
                        <Row id='video-lessons'>
                            <Col lg={1}></Col>
                            <Col lg={6}>
                                <h4>Lesson: {val.lesson}</h4>
                                <div dangerouslySetInnerHTML={{ __html: val.description}} />
                            </Col>
                            <Col lg={4}>
                                <YoutubeEmbed embedId={val.videoid} />
                            </Col>
                            <Col lg={1}></Col>
                        </Row>
                    </>
                    )

                })

            }
        </Row>
    </Container>
    )

}

export default Lessonsdetail;